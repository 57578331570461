<template>
    <div class="user-list">
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick($event)">
            <el-tab-pane label="系统操作日志" name="0">
                <OperateLog v-if="activeName==='0'" />
            </el-tab-pane>
            <el-tab-pane label="系统登录日志" name="1">
                <LoginLog v-if="activeName==='1'" />
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import OperateLog from './components/operateLog.vue'
import LoginLog from './components/loginLog.vue'
export default {
    components: { OperateLog,LoginLog  },
    props: {},
    data() {
        return {
            activeName:'0'
        };
    },
    created() {
    },
    methods: {
            handleClick(e){
                console.log('e',e);
            }
        },
};
</script>