<template>
    <div class="user-list">
        <!-- 筛选项 -->
        <el-form :inline="true" size="mini" :model="form" ref="filterFormRef" label-width="140px">
            <el-form-item label="用户操作时间:" prop="timeData">
                <el-date-picker v-model="form.timeData" type="datetimerange" range-separator="至" :default-time="['00:00:00','23:59:59']"  start-placeholder="开始日期"  end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss"> </el-date-picker>
            </el-form-item>
            <el-form-item label="操作行为:" prop="type">
                <el-select v-model="form.type" placeholder="请选择" clearable>
                    <el-option label="登录成功" value="1"></el-option>
                    <el-option label="退出成功" value="2"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item style="margin-left: 20px;">
                <el-button type="primary" icon="el-icon-search" @click="searchList">查询</el-button>
                <el-button icon="el-icon-refresh-left" @click="resetFilterForm">重置</el-button>
            </el-form-item>
        </el-form>
        <!-- 表格内容 -->
        <el-table :data="tableData" border style="width: 100%" v-loading="loading">
            <el-table-column prop="id" label="ID" width="80"></el-table-column>
            <el-table-column prop="userName" label="操作人"></el-table-column>
            <el-table-column prop="msg" label="登录行为"></el-table-column>
            <el-table-column prop="ipaddr" label="IP"></el-table-column>
            <el-table-column prop="os" label="设备信息"></el-table-column>
            <el-table-column prop="createAt" label="用户操作时间"></el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
                small
                @current-change="handleCurrentChange"
                :page-size="limit"
                layout="total, prev, pager, next, jumper"
                :total="total"
                :current-page="currentPage">
        </el-pagination>
        <!-- 分页 -->
    </div>
</template>

<script>
import { 
    getLoginInfoLogApi
} from "@/api/log.js";
export default {
    props: {},
    data() {
        return {
            tableData:[],
            loading:false,
            form: {
                timeData: [],
                msg: ""
            },
            total: 0,
            currentPage: 1,
            limit: 10,
        };
    },
    async created() {
        await this.getLoginInfoLogApiList()
    },
    methods: {
        async  getLoginInfoLogApiList(){ //系统
            try {
                this.loading=true
                let startTime=''
                let endTime=''
                if(this.form.timeData?.length>0){
                    startTime=this.form.timeData[0]
                    endTime=this.form.timeData[1]
                }
                let params={
                    current:this.currentPage,
                    size:this.limit,
                    startTime,
                    endTime,
                    type:Number(this.form.type)
                }
                this.tableData=[]
                let res =await getLoginInfoLogApi(params)
                this.tableData=res.data.records
                this.total=res.data.total
                this.currentPage=res.data.current
            } catch (error) {
                
            } finally{
                this.loading=false
            }},
        clearForm(){
            Object.keys(this.menuForm).forEach(item=>{
                this.menuForm[item]=''
            })
        },
         // 搜索重置
      async   resetFilterForm() {
            this.currentPage=1
            this.$refs.filterFormRef.resetFields();
            await this.getLoginInfoLogApiList()
        },
    async    handleCurrentChange(val) {
            this.currentPage = val
            await  this.getLoginInfoLogApiList()
        },
      async  searchList(){
            this.currentPage=1
            await this.getLoginInfoLogApiList()
        }
    },
};
</script>

<style scoped lang="scss">
.user-list {
}
</style>