<template>
    <div class="user-list">
        <!-- 筛选项 -->
        <el-form :inline="true" size="mini" :model="form" ref="filterFormRef" label-width="140px">
            <el-form-item label="用户操作时间:" prop="timeData">
                <el-date-picker v-model="form.timeData" type="datetimerange" range-separator="至" :default-time="['00:00:00','23:59:59']"  start-placeholder="开始日期"  end-placeholder="结束日期"  value-format="yyyy-MM-dd HH:mm:ss"> </el-date-picker>
            </el-form-item>
            <el-form-item label="操作行为:" prop="msg">
                <el-select v-model="form.msg" placeholder="请选择" clearable>
                    <el-option v-for="(item,index) in selectData" :key="index" :label="item.menuName" :value="item.menuCode"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item style="margin-left: 20px;">
                <el-button type="primary" icon="el-icon-search" @click="searchList">查询</el-button>
                <el-button icon="el-icon-refresh-left" @click="resetFilterForm">重置</el-button>
            </el-form-item>
        </el-form>
        <!-- 表格内容 -->
        <el-table :data="tableData" border style="width: 100%" v-loading="loading">
            <el-table-column prop="id" label="ID" width="80"></el-table-column>
            <el-table-column prop="menuName" label="操作行为"></el-table-column>
            <el-table-column prop="createBy" label="操作人"></el-table-column>
            <el-table-column prop="userName" label="操作数据">
                <template slot-scope="scope">
                   <span class="lookDetail" @click="detailHandle(scope.row)" style="color: #409eff;">查看数据</span>
                </template>
            </el-table-column>
            <el-table-column prop="operationDesc" label="操作备注"></el-table-column>
            <el-table-column prop="ip" label="IP"></el-table-column>
            <el-table-column prop="deviceInformation" label="设备信息"></el-table-column>
            <el-table-column prop="createAt" label="用户操作时间">
                <template slot-scope="scope">
                    <span>{{ parseTime(scope.row.createAt) }}</span>
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
                small
                @current-change="handleCurrentChange"
                :page-size="limit"
                layout="total, prev, pager, next, jumper"
                :total="total"
                :current-page="currentPage">
        </el-pagination>
        <!-- 分页 -->
        <el-dialog
                title="操作记录"
                :visible.sync="recordsVisible"
                width="80%"
                @close="recordsVisibleClose">
            <el-table border :data="operateData" height="400" style="width: 100%" v-loading="operateLoading">
                 <el-table-column prop="before"  label="提前数据" />
                 <el-table-column prop="after"  label="操作后数据" />
            </el-table>
             <!-- 分页 -->
            <el-pagination
                    small
                    @current-change="handleCurrentChangeDetail"
                    :page-size="limitDetail"
                    layout="total, prev, pager, next, jumper"
                    :total="totalDetail"
                    :current-page="currentPageDetail">
            </el-pagination>
        </el-dialog>
    </div>
</template>

<script>
import { 
    getOperationLogApi,
    getOptBehaviorApi,
    getLogDetilApi
} from "@/api/log.js";
export default {
    props: {},
    data() {
        return {
            recordsVisible:false,
            operateData:[],
            operateLoading:false,
            tableData:[],
            loading:false,
            form: {
                timeData: [],
                msg: ""
            },
            selectData:[],
            total: 0,
            currentPage: 1,
            limit: 10,
            totalDetail: 0,
            currentPageDetail: 1,
            limitDetail: 10,
            logId:null,
            rowDetail:{}
        };
    },
  async  created() {
        await this.getOptBehaviorApiList()
        await  this.getLoginInfoLogApiList()
    },
    methods: {
      async  handleCurrentChangeDetail(val){
            this.currentPageDetail=val
            await   this.getLogDetilApiList()
        },
     async   getLogDetilApiList(){
            try {
                let params={
                    current:this.currentPageDetail,
                    size:this.limitDetail,
                    logId:this.rowDetail.id,
                }
                let res=await getLogDetilApi(params)
                this.operateData=res.data.records
                this.totalDetail=res.data.total
                this.currentPageDetail=res.data.current
            } catch (error) {
                
            }
        },
     async   detailHandle(row){
            this.recordsVisible=true
            this.rowDetail=row
        await   this.getLogDetilApiList()
        },
        recordsVisibleClose(){
            this.recordsVisible=false
            this.currentPageDetail=1
            this.operateData=[]
        },
        async getOptBehaviorApiList(){
            try {
                let res= await getOptBehaviorApi()
                if(res.code===0){
                    this.selectData=res.data
                }
            } catch (error) {
                
            }
        },
        async  getLoginInfoLogApiList(){ //系统
            try {
                this.loading=true
                let startTime=''
                let endTime=''
                if(this.form.timeData?.length>0){
                    startTime=this.form.timeData[0]
                    endTime=this.form.timeData[1]
                }
                let params={
                    current:this.currentPage,
                    size:this.limit,
                    startTime,
                    endTime,
                    type:this.form.msg
                }
                this.tableData=[]
                let res =await getOperationLogApi(params)
                this.tableData=res.data.records
                this.total=res.data.total
                this.currentPage=res.data.current
            } catch (error) {
                
            } finally{
                this.loading=false
            }
        },
        clearForm(){
            Object.keys(this.menuForm).forEach(item=>{
                this.menuForm[item]=''
            })
        },
         // 搜索重置
     async    resetFilterForm() {
            this.$refs.filterFormRef.resetFields();
            this.currentPage=1
            await  this.getLoginInfoLogApiList()
        },
     async   handleCurrentChange(val) {
            this.currentPage = val
            await  this.getLoginInfoLogApiList()
        },
       async searchList(){
            this.currentPage=1
            await  this.getLoginInfoLogApiList()
        }
    },
};
</script>

<style scoped lang="scss">
.lookDetail:hover{
    cursor: pointer;
}
</style>