import axios from '../utils/axios'

let baseURL = '/sysLog'

// 系统操作日志-操作行为
export function getOptBehaviorApi(params) {
    return axios.get(baseURL+'/getOptBehavior', {params})
}
// 系统登录日志
export function getLoginInfoLogApi(params) {
    return axios.post(baseURL+'/getLoginInfoLog', params)
}
// 系统操作日志
export function getOperationLogApi(params) {
    return axios.post(baseURL+'/getOperationLog', params)
}
// 操作数据详情
export function getLogDetilApi(params) {
    return axios.post(baseURL+'/getLogDetil', params)
}

// 用户管理页面-操作记录
export function getUserPermissionLogAPI(params) {
    return axios.post(baseURL+'/getUserPermissionLog', params)
}